const firebaseConfig = {
  apiKey: "AIzaSyBXi4scQapxvmmr-chdPrZKNNO8_WVGNHE",
  authDomain: "memory-game-562e9.firebaseapp.com",
  projectId: "memory-game-562e9",
  storageBucket: "memory-game-562e9.appspot.com",
  messagingSenderId: "272653361999",
  appId: "1:272653361999:web:fd72da7772fbc7690b9b3a",
};

export default firebaseConfig;
